import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from "react-icons/io";
import { NavLink } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";
import { RiAlertLine } from "react-icons/ri";
import { AiFillAlert } from "react-icons/ai";

const News = () => {

    const [count , setCount] = useState('')

    useEffect(()=>{
        setCount('first')
    },[])
  return (
    <div> 
        <div style={{display:count==="first"?'block':'none'}}> 
        <div className='container mt-2'>
        <div className='row'>
              <div className='col-2'>
             <NavLink to="/mine">  <IoIosArrowBack  style={{fontSize:'22px', color:'#000'}}/></NavLink>
              </div>


              <div className='col-8'> 
                 <h5 className='text-center text-dark ' style={{fontWeight:'600'}}>SF Notice</h5>
              </div>
            </div>



            <div className='row mt-4' onClick={()=>{setCount('second')}}>
                <div className='col-9'  >
                    <h6 >Warning ! ! ! Dear new and old clients...</h6>
                    <span>2023-08-31 13:55:33</span>
                </div>
                <div className='col-3'> 
                    <span > More <IoIosArrowForward /></span>
                </div>  
            </div>
        </div>
        </div>


        <div style={{display:count==="second"?'block':'none'}}> 
        <div className='row mt-2'>
              <div className='col-2'>
              <IoIosArrowBack  style={{fontSize:'22px', color:'#000'}} onClick={()=>{setCount('first')}}/> 
              </div>


              <div className='col-8'> 
                 <h5 className='text-center text-dark ' style={{fontWeight:'600'}}>News</h5>
              </div>
            </div>

            <div className='row mt-5'>
                <div className='col-lg-12'>
                    <h3 style={{color:'red', textAlign:'center', }}><AiFillAlert className='me-3 ' style={{fontSize:'35px'}}/>Notice !</h3>
                     <h4 style={{color:'blue', textAlign:'center'}}>Dear new and old clients</h4>
                     <h4 style={{color:'red', textAlign:'center'}}><RiAlertLine className='me-2' style={{color:'orange'}} />  please pay correctly to complete the order within 20 minutes. </h4>

                     <h4 style={{color:'red', textAlign:'center'}}><RiAlertLine className='me-2' style={{color:'orange'}} />  If you pay for the order after 20 minutes </h4>


                     
                     <h4 style={{color:'red', textAlign:'center'}}><RiAlertLine className='me-2' style={{color:'orange'}} />  and the tokens are not received the company will not be responsible for the compensation for this error. </h4>

                     
                     <h4 className='mt-3' style={{color:'blue', textAlign:'center'}}>Please pay your order in time, thank you for your understanding and support and wish you a happy life every day!</h4>
                </div>
            </div>
        </div>  
    </div>
  )
}

export default News