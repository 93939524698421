import React from 'react'
import { NavLink } from 'react-router-dom'

import { MdKeyboardArrowLeft } from "react-icons/md";
import { FaCalendarCheck } from "react-icons/fa";
import avtaar from '../../src/Components/Img/abc.png'
import { FaSteam } from "react-icons/fa";
import { FaPersonArrowUpFromLine } from "react-icons/fa6";
import { GiDividedSpiral } from "react-icons/gi";
import { LiaShareAltSolid } from "react-icons/lia";
import { LuWallet } from "react-icons/lu";
import { IoToday } from "react-icons/io5";
import { SiVirustotal } from "react-icons/si";
import { FaRegCalendarCheck } from "react-icons/fa6";
import { RiTeamFill } from "react-icons/ri";

const Promotion = () => {
  return (
    <div>
        <div className='container pt-2'>
    <div className='row'>
        <div className='col-1'>
            <div  >
           
          <NavLink to="/mine" style={{textDecoration:'none', color:'#000'}}>  <MdKeyboardArrowLeft  style={{fontSize:'18px'}}/></NavLink> 

            </div>

        </div>
        <div className='col-10'>
            <div  >
           
            <h3 className='text-center'>Promotion and Revenue  </h3>

            </div>

        </div>
    </div>
   </div>

   <div className='container mt-3 pb-2' style={{borderBottom:'0.1px solid #dfdddd'}}>
    <div className='row'>
        <div className='col-3 px-0'>
            <img src={avtaar} style={{width:'100%'}}/>
        </div>
        <div className='col-5 ps-0 mt-2'>
            <h6 style={{color:'gray'}}>Reward ratio:1.20 <br/>
Enjoy dividends:no</h6>
        </div>
        <div className='col-4 ps-0 mt-3'>
           <NavLink to='/AccountInformation' style={{textDecoration:'none'}}> <h6 style={{color:'gray'}}> ID: 6822028858</h6></NavLink>
        </div>
    </div>
   </div>



   <div className='container mt-4 pt-2' style={{backgroundColor:'#e7e7e7'}}>
    <div className='row'>
        <div className='col-3'>
            <FaSteam  className='ms-2' style={{fontSize:'38px', color:'#3771df'}}/>
            <h6 className='pt-2 text-primary' style={{fontWeight:'600'}}>My Team</h6>

        </div>
        <div className='col-3'>
          <NavLink to="/commision" style={{textDecoration:'none'}}>  <FaPersonArrowUpFromLine  className='ms-4' style={{fontSize:'38px', color:'#3771df'}}/>
            <h6 className='pt-2 text-dark ' style={{fontWeight:'600'}}>Commission</h6></NavLink>

        </div>
        <div className='col-3'>
            <NavLink to="/Dividend" style={{textDecoration:'none'}}><GiDividedSpiral  className='ms-3' style={{fontSize:'38px', color:'#3771df'}}/>
            <h6 className='pt-2 text-dark' style={{fontWeight:'600'}}>Dividend</h6></NavLink>

        </div>
        <div className='col-3'>
          <NavLink to="/inviteLink" style={{textDecoration:'none'}}> <LiaShareAltSolid className='ms-3' style={{fontSize:'38px', color:'#0b1750', fontWeight:'600'}}/>
            <h6 className='pt-2 text-dark' style={{fontWeight:'600'}}>Invite link</h6>
            </NavLink>
        </div>
    </div>
   </div>


   <div className="container mt-4">
    <div className='row'>
        <h5>My Commission</h5>
        <div className='col-4 mt-3'>

            <div className='card  gajju  border-0 w-100' style={{borderRadius:'10px'}}>
                <div className='card-body p-0'>
                    <div style={{display:'flex', justifyContent:'center'}}> 
                   <LuWallet className='mt-2 text-primary' style={{ fontSize:'27px'}} />
                    </div>
                   <h6 className='text-center py-1'>Yesterday</h6>
                   <h5 className='text-center'>0</h5>
                </div>
            </div>

        </div>


        <div className='col-4 mt-3'>

<div className='card  gajju  border-0 w-100' style={{borderRadius:'10px'}}>
    <div className='card-body p-0'>
        <div style={{display:'flex', justifyContent:'center'}}> 
       <IoToday className='mt-2 ' style={{ fontSize:'27px',color:'#4171ff'}} />
        </div>
       <h6 className='text-center py-1'>Today</h6>
       <h5 className='text-center'>0</h5>
    </div>
</div>

</div>






<div className='col-4 mt-3'>

<div className='card  gajju  border-0 w-100' style={{borderRadius:'10px'}}>
    <div className='card-body p-0'>
        <div style={{display:'flex', justifyContent:'center'}}> 
       <SiVirustotal className='mt-2 ' style={{ fontSize:'27px', color:'#f56c27'}} />
        </div>
       <h6 className='text-center py-1'>Total</h6>
       <h5 className='text-center'>0</h5>
    </div>
</div>

</div>
    </div>

   </div>


   <div className="container mt-4">
    <div className='row'>
        <h5>Team Infos</h5>
        <div className='col-4 mt-3'>

            <div className='card  gajju  border-0 w-100' style={{borderRadius:'10px'}}>
                <div className='card-body p-0'>
                    <div style={{display:'flex', justifyContent:'center'}}> 
                   <FaRegCalendarCheck className='mt-2 text-primary' style={{ fontSize:'27px'}} />
                    </div>
                   <h6 className='text-center py-1'>New ct Today</h6>
                   <h5 className='text-center'>0</h5>
                </div>
            </div>

        </div>


        <div className='col-4 mt-3'>

<div className='card  gajju  border-0 w-100' style={{borderRadius:'10px'}}>
    <div className='card-body p-0'>
        <div style={{display:'flex', justifyContent:'center'}}> 
       <IoToday className='mt-2 ' style={{ fontSize:'27px',color:'#4171ff'}} />
        </div>
       <h6 className='text-center py-1'>Register Today</h6>
       <h5 className='text-center'>0</h5>
    </div>
</div>

</div>






<div className='col-4 mt-3'>

<div className='card  gajju  border-0 w-100' style={{borderRadius:'10px'}}>
    <div className='card-body p-0'>
        <div style={{display:'flex', justifyContent:'center'}}> 
       <RiTeamFill className='mt-2 text-primary ' style={{ fontSize:'27px', }} />
        </div>
       <h6 className='text-center py-1'>Team Count</h6>
       <h5 className='text-center'>0</h5>
    </div>
</div>

</div>
    </div>







    <div className='row'> 
    <div className='col-1'></div>
        <div className='col-5 mt-3'>

            <div className='card  gajju  border-0 w-100' style={{borderRadius:'10px'}}>
                <div className='card-body p-0'>
                    <div style={{display:'flex', justifyContent:'center'}}> 
                   <IoToday className='mt-2 text-primary' style={{ fontSize:'27px'}} />
                    </div>
                   <h6 className='text-center py-1'>Today buy Itoken</h6>
                   <h5 className='text-center'>0</h5>
                </div>
            </div>

        </div>


        <div className='col-5 mt-3'>

<div className='card  gajju  border-0 w-100' style={{borderRadius:'10px'}}>
    <div className='card-body p-0'>
        <div style={{display:'flex', justifyContent:'center'}}> 
       <SiVirustotal className='mt-2 ' style={{ fontSize:'27px', color:'#f56c27'}} />
        </div>
       <h6 className='text-center py-1'>Total Buy IToken</h6>
       <h5 className='text-center'>0</h5>
    </div>
</div>

</div>







    </div>

   </div>
    </div>
  )
}

export default Promotion