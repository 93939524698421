import React from 'react'
import { NavLink } from 'react-router-dom'
import { MdKeyboardArrowLeft } from "react-icons/md";

const InviteLink = () => {
  return (
    <>
         <div className='container pt-2'>
    <div className='row'>
        <div className='col-1'>
            <div  >
           
          <NavLink to="/mine" style={{textDecoration:'none', color:'#000'}}>  <MdKeyboardArrowLeft  style={{fontSize:'18px'}}/></NavLink> 

            </div>

        </div>
        <div className='col-10'>
            <div  >
           
            <h3 className='text-center'> Invite link Manage  </h3>

            </div>

        </div>
    </div>
   </div>


   <div className='container mt-4 pb-3' style={{borderBottom:'1px solid #dbdbdb'}}>
    <div className='row'>
        <div className='col-7'>
            <h6 className='mb-0' style={{color:'#000'}}>b83baeMmo( 1.19 % )</h6>
            <span className='mb-1' style={{color:'gray'}}>Register count: 0</span> <br/>
            <h6>2024-03-09 21:33:59</h6>
        </div>

        <div className='col-5 mt-3'>
            <h6 className='mb-0'  style={{float:'right'}}>operating</h6>
           
        </div>
 
    </div>
   </div>


   <div className='container '>
    <div className='row'>
        <div className='col-12'>
        <div>
            <button className='btn btn-primary w-100 fixed-bottom mb-3 py-2  ' style={{borderRadius:'13px   '}} data-bs-toggle="modal" data-bs-target="#exampleModal">Add Invite link </button>
        </div>
        </div>
    </div>
   </div>












   {/* <!-- Button trigger modal --> */}
 

{/* <!-- Modal --> */}
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{backdropFilter:'blur(8px)'}}>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="exampleModalLabel">Add Invite Link</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         <h2>Reward ratio (%)</h2>

         <div class="input-group mt-3">
  <input type="text" class="form-control py-2" placeholder="1.20" aria-label="Recipient's username" aria-describedby="basic-addon2" style={{borderRight:'none', fontSize:'18px  ', color:'#000'}}/>
  <span class="input-group-text" id="basic-addon2" style={{backgroundColor:'#fff'}}>Max</span>
</div>

<span style={{color:'#959595', fontSize:'14px'}}>you can get 0.00% Itoken</span>
      </div>


      <div class="modal-footer mt-3" style={{display:'flex', justifyContent:'space-around'}}>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style={{backgroundColor:'transparent', color:'gray', fontSize:'20px'}}>Close</button>
        <button type="button" class="btn btn-primary text-primary" style={{backgroundColor:'transparent',   fontSize:'20px', fontWeight:'600'}}>Save  </button>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default InviteLink