import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BottomBar from './BottomBar';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className='container p-0 mt-3'>
        <div className='row'>
          <div className='col-12 p-0'>
            
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='p-0'>
                  <Tab label="INR" {...a11yProps(0)} className='lowercase-tab2' /> 
                  <Tab label="USDT" {...a11yProps(1)} className='lowercase-tab2'/> 
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
              <div class="input-group my-3">
                <span class="input-group-text px-0" style={{backgroundColor:'transparent', border:'none', float:'right', borderBottom:'0.5px solid #dddddd'}}>₹</span>
                <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)"  placeholder="Enter the amount" style={{boxShadow:'none',border:'none', borderBottom:'0.5px solid #dddddd'}}/>
                <span class="input-group-text" style={{backgroundColor:'transparent', border:'none', float:'right',borderBottom:'0.5px solid #dddddd', color:'#ff9b00'}}> = 0 I Token</span>
                </div>

                <p className='ps-1' style={{color:"red", fontSize:'12px'}}>Estimated Reward Itoken:0(1.20%:0)</p>
                <p className='ps-1' style={{color:"red", fontSize:'12px'}}> You will receve : 0itoken</p>


                <div style={{display:'flex', justifyContent:'center'}}>
                    <button className='btn btn-primary' style={{  width:'95%', padding:'10px', borderRadius:'8px'}}>Confirm Buy</button>
                </div>
                <div style={{display:'flex', justifyContent:'center'}}>
                <span style={{fontSize:'12px' , textAlign:'center'}}>Exchange  is free of change</span>
                </div>
              </CustomTabPanel>














              <CustomTabPanel value={value} index={1}>
             <div class="input-group my-3">
  <span class="input-group-text px-0" style={{backgroundColor:'transparent', border:'none', float:'right', borderBottom:'0.5px solid #dddddd'}}> $ </span>
  <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)"  placeholder="Enter the  quantity" style={{boxShadow:'none',border:'none', borderBottom:'0.5px solid #dddddd'}}/>
  <span class="input-group-text" style={{backgroundColor:'transparent', border:'none', float:'right',borderBottom:'0.5px solid #dddddd', color:'#ff9b00'}}> = 0 I Token</span>
</div>

<p className='ps-1' style={{color:"red", fontSize:'12px'}}>Estimated Reward Itoken:0(1.20%:0)</p>
<p className='ps-1' style={{color:"red", fontSize:'12px'}}> You will receve : 0itoken</p>


<div style={{display:'flex', justifyContent:'center'}}>
    <button className='btn btn-primary' style={{  width:'95%', padding:'10px', borderRadius:'8px'}}>Confirm Buy</button>
</div>
<div style={{display:'flex', justifyContent:'center'}}>
<span style={{fontSize:'12px' , textAlign:'center'}}>Exchange  is free of change</span>
</div>
              </CustomTabPanel>  
            </Box>
          </div>
        </div>
      </div>
      <BottomBar/>
    </div>
  );
}
