import React from 'react'
import { FaTelegram } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import { MdKeyboardArrowLeft } from "react-icons/md";

const CustomerServices = () => {
  return (
    <>
    
    <div className='container py-3'>
            <div className='row'>
                <div className='col-1'> 
                <NavLink to="/mine" style={{textDecoration:'none'}}> <MdKeyboardArrowLeft  style={{fontSize:'28px', color:'#000 '}}/> </NavLink>
                </div>
                <div className='col-10'> 
                <h3 className='text-center'>Customer Service</h3>
                </div>
            </div>
        </div>

        <div className='container'>
            <div className='row'>
                <div className='col-1'>
                <FaTelegram style={{fontSize:'22px', color:'#0087ff'}} />

                </div>
                <div className='col-8'>
                 <h6 className='mb-0'>SFPay Online Customer Service</h6>
                 <span style={{color:'gray', fontSize:'13px'}}>SF service</span>

                </div>
                <div className='col-3'> 
                 <button className='btn btn-primary w-100'>Go</button>

                </div>
            </div>
        </div>
    </>
  )
}

export default CustomerServices