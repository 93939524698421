



import React, { useEffect, useState } from 'react'
import { MdKeyboardArrowLeft } from "react-icons/md";
import { NavLink, useNavigate } from 'react-router-dom';
import Avtaar from './Img/abc.png'
import { FaRegCopy } from "react-icons/fa";
import { Api_Url } from '../env';
import toast, { Toaster } from 'react-hot-toast';

const AccountInformation = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
  
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };
  
    const handleConfirmPasswordChange = (event) => {
      setConfirmPassword(event.target.value);
    };

    const Navigate = useNavigate();

    const [data , setData] = useState([]);
  
    const validateInputs = () => {
      let valid = true;
      if (!password) {
        setPasswordError('Password is required');
        valid = false;
      } else {
        setPasswordError('');
      }
      if (!confirmPassword) {
        setConfirmPasswordError('Confirm Password is required');
        valid = false;
      } else if (confirmPassword !== password) {
        setConfirmPasswordError('Passwords do not match');
        valid = false;
      } else {
        setConfirmPasswordError('');
      }
      return valid;
    };
  
    const handleModifyClick = () => {
      if (validateInputs()) {
        // Proceed with your logic here
         
      } else {
        // Inputs are invalid, handle accordingly
      }
    };


    const GetData = async() =>{
      try {
        const formdata = new FormData();
  formdata.append("token", `${localStorage.getItem('token')}`);
  
  const requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow"
  };
  
  fetch(`${Api_Url}/api/get-dashboard`, requestOptions)
    .then((response) => response.json())
    .then((result) =>  {
      if(result.status == "001"){
          setData(result.user)
      }
    })
    .catch((error) => console.error(error));
      } catch (error) {
        
      }
    }

    const handleClick = () => {
      handleModifyClick();
      ChangePassword2();
    };



    

    const ChangePassword2 = ()=>{

      const formdata = new FormData();
  formdata.append("password", password);
  formdata.append("password_confirmation", confirmPassword);
  formdata.append("token", `${localStorage.getItem('token')}`);
  
  
  
  const requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow"
  };
  
  fetch(`${Api_Url}/api/validate-reset-password`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if(result.status == "001"){
        toast.success(result.message)
        
        setTimeout(() => {
          Navigate('/AccountInformation') 
           setPassword(' ')
           setConfirmPassword(' ')
        }, 2000);
      }else{
        toast.error(result.message)
      }
    })
    .catch((error) => console.error(error));
    }

    useEffect(()=>{
      GetData()
    },[])
  return (

    <div>
      <Toaster/>
        <div className='container pt-2'>
    <div className='row'>
        <div className='col-1'>
            <div  >
           
          <NavLink to="/AccountInformation" style={{textDecoration:'none', color:'#000'}}>  <MdKeyboardArrowLeft  style={{fontSize:'18px'}}/></NavLink> 

            </div>

        </div>
        <div className='col-10'>
            <div  >
           
            <h6 className='text-center'>Modify Password </h6>


            <h1  className='text-center mt-3' style={{fontWeight:'600'}}>PM Wallet</h1>

            </div>

        </div>
    </div>
   </div>


   <div className='container'>
    <div className='row'>
        <div className='col-6 m-auto mt-4'>
            <div style={{display:'flex', justifyContent:'center'}}> 
        <img src={Avtaar} style={{width:'80%'}}/>
  </div>
        <h6 className='text-center mt-4'>Modify Password from </h6>
        </div>
    </div>


    <div className='row mt-3 '  >
        <div className='col-6'>
            <span>Phone:</span>
        </div>

        <div className='col-6'>
            <span style={{color:'gray', float:'right'}}>{data?.number}   </span>
        </div>
    </div>
    <hr style={{color:'#d5d5d5'}}/>



    <div className='row mt-3'>
        <div className='col-6'>
          <span>Password:</span>
        </div>
        <div className='col-6'>
          <span style={{ color: 'gray', float: 'right' }}>
            <input
              type="password"
              className="form-control border-0 text-end"
              placeholder='Enter Password'
              value={password}
              onChange={handlePasswordChange}
              style={{ float: 'right', boxShadow: 'none', borderColor: passwordError ? 'red' : '' }}
            />
          </span>
         
        </div>
      </div>
      <hr style={{ color: '#d5d5d5' }} />
      {passwordError && <div style={{ color: 'red' }}>{passwordError}</div>}
      <div className='row mt-3'>
        <div className='col-6'>
          <span>Confirm:</span>
        </div>
        <div className='col-6'>
          <span style={{ color: 'gray', float: 'right' }}>
            <input
              type="password"
              className="form-control border-0 text-end"
              placeholder='Password Again'
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              style={{ float: 'right', boxShadow: 'none', borderColor: confirmPasswordError ? 'red' : '' }}
            />
          </span>
         
        </div>
      </div>
      <hr style={{ color: '#d5d5d5' }} />
      {confirmPasswordError && <div style={{ color: 'red' }}>{confirmPasswordError}</div>}
      <div className='mt-5'>
        <button className='btn btn-primary w-100' onClick={handleClick}>Modify</button>
      </div>
   </div>
    </div>

  )
}

export default AccountInformation





 