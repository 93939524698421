import React from 'react'
import { MdKeyboardArrowLeft } from "react-icons/md";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { IoFileTrayStackedSharp } from "react-icons/io5";
import { NavLink } from 'react-router-dom';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p:0}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const SellHistory = () => {
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>

        <div className='container py-3'>
            <div className='row'>
                <div className='col-1'> 
                   <NavLink to="/mine" style={{textDecoration:'#000'}}> <MdKeyboardArrowLeft  style={{fontSize:'22px'}}/> </NavLink>
                </div>
                <div className='col-10'> 
                <h3 className='text-center'>Sell history</h3>
                </div>
            </div>
        </div>



        <div className='container-fluid py-3'>
            <div className='row'>
            <div className='col-12 p-0'>
               <div>
               <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{backgroundColor:'#e7e7e7', borderRadius:'12px'}}>
          <Tab label="Paying" {...a11yProps(0)} className="lowercase-tab"/>
          <Tab label="success" {...a11yProps(1)} className="lowercase-tab"/>
          <Tab label="Timeout" {...a11yProps(2)} className="lowercase-tab" />
          <Tab label="offline" {...a11yProps(2)} className="lowercase-tab" />
        </Tabs>
      </Box>
   
    </Box>
               </div>
            </div>



            <div className='col-12 px-0 mt-4'>
            <CustomTabPanel value={value} index={0}>

                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 p-0'>
                            <div className='py-4' style={{display:'flex', justifyContent:'center'}}>
                            <IoFileTrayStackedSharp style={{color:'#5568f9', fontSize:'70px'}} />

                            </div>
                                <h5 className='text-center'>There are no sales orders at the moment. If you have enabled consignment, please check the authorization of the key partner and whether the kyc partner can receive payment normally or contact customer service in time</h5>


                                    <NavLink to="/sell"><button className='btn mt-4' style={{backgroundColor:'#0202cb', color:'#fff', padding:'10px', width:'100%', borderRadius:'20px'}}>Check Sell state</button> </NavLink>
                                <div>
                                </div>
                        </div>
                    </div>
                </div>
        </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 p-0'>
                            <div className='py-4' style={{display:'flex', justifyContent:'center'}}>
                            <IoFileTrayStackedSharp style={{color:'#5568f9', fontSize:'70px'}} />

                            </div>
                                <h5 className='text-center'>There are no sales orders at the moment. If you have enabled consignment, please check the authorization of the key partner and whether the kyc partner can receive payment normally or contact customer service in time</h5>


                                    <NavLink to="/sell"><button className='btn mt-4' style={{backgroundColor:'#0202cb', color:'#fff', padding:'10px', width:'100%', borderRadius:'20px'}}>Check Sell state</button> </NavLink>
                                <div>
                                </div>
                        </div>
                    </div>
                </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 p-0'>
                            <div className='py-4' style={{display:'flex', justifyContent:'center'}}>
                            <IoFileTrayStackedSharp style={{color:'#5568f9', fontSize:'70px'}} />

                            </div>
                                <h5 className='text-center'>There are no sales orders at the moment. If you have enabled consignment, please check the authorization of the key partner and whether the kyc partner can receive payment normally or contact customer service in time</h5>


                                    <NavLink to="/sell"><button className='btn mt-4' style={{backgroundColor:'#0202cb', color:'#fff', padding:'10px', width:'100%', borderRadius:'20px'}}>Check Sell state</button> </NavLink>
                                <div>
                                </div>
                        </div>
                    </div>
                </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 p-0'>
                            <div className='py-4' style={{display:'flex', justifyContent:'center'}}>
                            <IoFileTrayStackedSharp style={{color:'#5568f9', fontSize:'70px'}} />

                            </div>
                                <h5 className='text-center'>There are no sales orders at the moment. If you have enabled consignment, please check the authorization of the key partner and whether the kyc partner can receive payment normally or contact customer service in time</h5>


                                    <NavLink to="/sell"><button className='btn mt-4' style={{backgroundColor:'#0202cb', color:'#fff', padding:'10px', width:'100%', borderRadius:'20px'}}>Check Sell state</button> </NavLink>
                                <div>
                                </div>
                        </div>
                    </div>
                </div>
      </CustomTabPanel>
            </div>
            </div>
        </div>
    </div>
  )
}

export default SellHistory