import React, { useRef, useState } from 'react'
import { IoIosArrowBack } from "react-icons/io";
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
import { Api_Url } from '../env';
import toast, { Toaster } from 'react-hot-toast';
import screen from '../Components/Img/pay.jpg'
const DepositFund = () => {


  const [txnId , settxnId] = useState();
  const [Amount , setAmount] = useState(); 


  const [image, setImage] = useState(null);

  const fileInputRef = useRef(null);

   
  const handleImageChange = (e) => {
    const selectedImage = fileInputRef.current.files[0];
    setImage(selectedImage);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };



  const Navigate = useNavigate();



  
  const UploadImage = () => {
    const formdata = new FormData();
    formdata.append("token", localStorage.getItem('token'));
    formdata.append("amount", Amount);
    formdata.append("screenshot",image);
    formdata.append("txn_id", txnId);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };

    fetch(`${Api_Url}/api/deposit-amount`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "001") {
          toast.success(result.msg);

          Navigate('/mine')

          setTimeout(() => {
            setAmount(' ')
            settxnId(' ')
            
          }, 5500);
        } else {
          toast.error(result.msg);
          setAmount(' ')
          settxnId(' ')
        }
      })
      .catch((error) => console.error(error));
  };



  return (
    <div>

      <Toaster/>
        <div className='container pt-2'>
            <div className='row'>
              <div className='col-2'>
             <NavLink to="/mine">  <IoIosArrowBack  style={{fontSize:'22px', color:'#000'}}/></NavLink>
              </div>
 
              <div className='col-8'> 
                 <h5 className='text-center'>Deposit Fund</h5>
              </div>
            </div>





            <div className='row mt-3'>

              <div className='col-lg-4 col-sm-12'>
                <img src={`${Api_Url}/public/scanner/phonepay.jpg`}  style={{width:'100%'}}/>
              </div>
              <div className='col-12'>
              <div className='col-lg-12 col-md-6 col-sm-12 mt-4 m-auto'> 

               

                <div className='card yac border-0' style={{backgroundColor:'transparent'}}>
                  <div className='card-body'>  
                <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label   fs-5"> Amount</label>
                <input type="number" class="form-control" id="exampleInputEmail1"  value={Amount} 
                onChange={(e)=>{setAmount(e.target.value)}}/>

                </div> 

                    <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label   fs-5"> Transaction Id</label>
                    <input type="text" class="form-control" id="exampleInputEmail1" value={txnId}
                    aria-describedby="emailHelp" onChange={(e)=>{settxnId(e.target.value)}}/>

                    </div>


                    <div className="mb-3 mt-4">
        {/* <label htmlFor="exampleInputEmail1" className="form-label fs-5">Choose Image:</label> */}
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <button className='btn btn-primary' onClick={handleButtonClick}>Upload Screenshot</button>
        {image && (
          <div className='mt-4'>
            <h4>Preview:</h4>
            <img src={URL.createObjectURL(image)} alt="Preview" style={{ width: '100%', height: 'auto' }} />
          </div>
        )}
      </div>


<div>
  <button className='btn btn-primary py-2 w-100 fs-3 mt-5' onClick={()=>{UploadImage()}}>Submit</button>
</div>
 
  

</div>


</div>
</div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default DepositFund