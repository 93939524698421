import React from 'react'
import { CiHome } from "react-icons/ci";
import { GrTransaction } from "react-icons/gr";
import { CiCircleMinus } from "react-icons/ci";
import { NavLink, useLocation } from 'react-router-dom';

const BottomBar = () => {

    const Location = useLocation()

    const sty = Location.pathname == "/Home" ?{color:"blue",fontWeight:'600'} : { fontWeight:'600'}
    const sty1 = Location.pathname == "/transaction" ?{color:"blue",fontWeight:'600'} : { fontWeight:'600'}

    const sty2 = Location.pathname == "/mine" ?{color:"blue",fontWeight:'600'} : { fontWeight:'600'}

  return (
    <>
   <div className='container'>
    <div className='row'>
        <div className='col-12'>
            <div className='fixed-bottom py-2' style={{display:'flex', alignItems:'center', justifyContent:'space-around',backgroundColor:'#f5f5f5'}}>

                <NavLink to='/Home' style={{listStyle:'none',color:'#000', textDecoration:'none', ...sty}}><div><CiHome className='ms-3'  style={{fontSize:'25px', textAlign:'center' 
            }}/><h5>Home</h5></div></NavLink>

               <NavLink to="/transaction" style={{listStyle:'none',color:'#000', textDecoration:'none', ...sty1}}><div><GrTransaction className='ms-4'  style={{fontSize:'25px', textAlign:'center'}}/><h5>Transaction</h5></div> </NavLink>

                <NavLink to="/mine" style={{listStyle:'none',color:'#000', textDecoration:'none', ...sty2}}><div><CiCircleMinus className='ms-2'  style={{fontSize:'25px', textAlign:'center'}}/><h5>Mine</h5></div></NavLink> 

                </div>
        </div>
    </div>
   </div>
    </>
  )
}

export default BottomBar