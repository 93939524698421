import React, { useEffect, useState } from 'react'
import { MdKeyboardArrowLeft } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import Avtaar from './Img/abc.png'
import { FaRegCopy } from "react-icons/fa";
import { Api_Url } from '../env';

const ChangePass = () => {
    const [idText, setIdText] = useState('6822028852');
    const [phoneText, setPhoneText] = useState('9145784512');
    const [data , setData] = useState([]);


    const copyTextToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => alert('Copied to clipboard'))
            .catch((error) => console.error('Could not copy text: ', error));
    };


    const GetData = async() =>{
        try {
          const formdata = new FormData();
    formdata.append("token", `${localStorage.getItem('token')}`);
    
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };
    
    fetch(`${Api_Url}/api/get-dashboard`, requestOptions)
      .then((response) => response.json())
      .then((result) =>  {
        if(result.status == "001"){
            setData(result.user)
        }
      })
      .catch((error) => console.error(error));
        } catch (error) {
          
        }
      }

      useEffect(()=>{
        GetData()
      },[])
  return (

    <div>
        <div className='container pt-2'>
    <div className='row'>
        <div className='col-1'>
            <div  >
           
          <NavLink to="/mine" style={{textDecoration:'none', color:'#000'}}>  <MdKeyboardArrowLeft  style={{fontSize:'18px'}}/></NavLink> 

            </div>

        </div>
        <div className='col-10'>
            <div  >
           
            <h5 className='text-center'>Account Info </h5>

            </div>

        </div>
    </div>
   </div>


   <div className='container'>
    <div className='row'>
        <div className='col-4 m-auto mt-4'>
        <img src={Avtaar} style={{width:'100%'}}/>
        </div>
    </div>


    <div className='row mt-3 '  >
        <div className='col-6'>
            <span>ID:</span>
        </div>

        <div className='col-6'>
            <span style={{color:'gray', float:'right'}}> {data?.username}  <FaRegCopy  className='ms-3' onClick={() => copyTextToClipboard(idText)}/></span>
        </div>
    </div>
    <hr style={{color:'#d5d5d5'}}/>



    <div className='row mt-3 '  >
        <div className='col-6'>
            <span>Phone:</span>
        </div>

        <div className='col-6'>
            <span style={{color:'gray', float:'right'}}>{data?.number}  <FaRegCopy  className='ms-3' onClick={() => copyTextToClipboard(phoneText)}/></span>
        </div>
    </div>
    <hr style={{color:'#d5d5d5'}}/>

    
    <div className='row mt-3 '  >
        <div className='col-6'>
            <span>Email:</span>
        </div>

        <div className='col-6'>
            <span style={{color:'gray', float:'right'}}>{data?.email} </span>
        </div>
    </div>
    <hr style={{color:'#d5d5d5'}}/>


    <div className='mt-5'>
      <NavLink to="/changePass">   <button className='btn btn-primary w-100 py-2'>Change Password</button> </NavLink>
    </div>
   </div>
    </div>

  )
}

export default ChangePass
