import React, { useState } from 'react'
import { MdKeyboardArrowLeft } from "react-icons/md";
import { FaCalendarCheck } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { FaChevronRight } from "react-icons/fa6";

const Bank = () => {

    const [count , setCount] = useState(1);
    const [bankName, setBankName] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [yourName, setYourName] = useState('');
    const [bankAccount, setBankAccount] = useState('');
    const [errors, setErrors] = useState({
      bankName: false,
      ifscCode: false,
      yourName: false,
      bankAccount: false,
    });
  
    const handleConfirmClick = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
      
        const newErrors = {
          bankName: !bankName.trim(),
          ifscCode: !ifscCode.trim(),
          yourName: !yourName.trim(),
          bankAccount: !bankAccount.trim(),
        };
      
        setErrors(newErrors);
      
        // If any field is empty, prevent further action
        if (Object.values(newErrors).some((error) => error)) {
          return;
        }
      
        // Perform the action you want to take on confirmation
        // For example, you can submit a form or trigger an API call here
        console.log('Form submitted successfully!');
      };
  return (
    <>

    <div  style={{display:count==1?"block":'none'}}>
   <div className='container pt-2'>
    <div className='row'>
        <div className='col-1'>
            <div  >
           
          <NavLink to="/mine" style={{textDecoration:'none', color:'#000'}}>  <MdKeyboardArrowLeft  style={{fontSize:'18px'}}/></NavLink> 

            </div>

        </div>
        <div className='col-10'>
            <div  >
           
            <h3 className='text-center'>Bank KYC Partner  </h3>

            </div>

        </div>
    </div>
   </div>


   <div className='container mt-5'>
    <div className='row'>
        <div className='col-12'>
            <div style={{display:'flex', justifyContent:'center'}}>
        <FaCalendarCheck  style={{fontSize:'55px', color:'#5b5bff'}}/>


    </div>
    <div className='pt-2' style={{display:'flex', justifyContent:'center'}}>
        <span style={{fontSize:'12px' ,textAlign:'center'}}> no payment method</span>
    </div>
    </div> 

    <div className='col-12 mt-4'>
        <button className='btn btn-primary w-100 py-2' onClick={()=>{setCount(count+1)}}>Add Bank Card</button>
    </div> 
    {/* <div className='col-12 mt-4' style={{}}>
        <button className='btn btn-primary w-100 py-3 fixed-bottom mb-4' style={{borderRadius:'25px'}} onClick={()=>{setCount(count+1)}}>Link New UPI Kyc Partner</button>
    </div> */}
        </div>
   </div>

   </div>









   <div style={{display:count==2?"block":'none'}}>
   <div className='container pt-2'>
    <div className='row py-2' style={{borderBottom:'0.5px solid #e9e9e9'}}>
     
        <div className='col-1'>
            <div  >
           
          <NavLink to="/mine" style={{textDecoration:'none', color:'#000'}}>  <MdKeyboardArrowLeft  style={{fontSize:'18px'}}/></NavLink> 

            </div>

        </div>
        <div className='col-10'>
            <div  >
           
            <h3 className='text-center'>Bank kyc Partner  </h3>

            </div>

        </div>
    </div>
     


    <div className='row mt-3'>
        <div className='col-12'>
            <div  >
                
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': {   width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
      <TextField
        id="filled-password-input"
        label="Enter bank name"
        type="text"
        autoComplete="current-password"
        variant="filled"
        style={{ width: '100%', backgroundColor: '#fff' }}
        value={bankName}
        onChange={(e) => setBankName(e.target.value)}
      />
      {errors.bankName && <span style={{ color: 'red' }}>Bank name is required</span>}

      <TextField
        className='mt-3'
        id="filled-password-input"
        label="Enter ifsc Code"
        type="text"
        autoComplete="current-password"
        variant="filled"
        style={{ width: '100%', backgroundColor: 'transparent' }}
        value={ifscCode}
        onChange={(e) => setIfscCode(e.target.value)}
      />
      {errors.ifscCode && <span style={{ color: 'red' }}>IFSC Code is required</span>}

      <TextField
        className='mt-3'
        id="filled-password-input"
        label="Enter your name"
        type="text"
        autoComplete="current-password"
        variant="filled"
        style={{ width: '100%', backgroundColor: 'transparent' }}
        value={yourName}
        onChange={(e) => setYourName(e.target.value)}
      />
      {errors.yourName && <span style={{ color: 'red' }}>Your name is required</span>}

      <TextField
        className='mt-3'
        id="filled-password-input"
        label="Enter your bank account"
        type="text"
        autoComplete="current-password"
        variant="filled"
        style={{ width: '100%', backgroundColor: 'transparent' }}
        value={bankAccount}
        onChange={(e) => setBankAccount(e.target.value)}
      />
      {errors.bankAccount && <span style={{ color: 'red' }}>Bank account is required</span>}

      <div>
        <button className='btn btn-primary py-3 w-100 mt-3' onClick={handleConfirmClick}>
          Confirm
        </button>
      </div>
    </div>
       
    </Box>

    <div className='mt-3'>
            <h5 > Notice </h5>


            <p className='pt-2' style={{color:'red', fontSize:'14px'}}>Please ensure that you fill in real personal information. The information you fill in will be used for real transactions. If there is a remittance error caused by incorrect information, will not accept it</p>

         
      </div>
            </div>
        </div>
    </div>
   </div>
   </div>

  
    </>
  )
}

export default Bank