import React, { useEffect } from 'react' 
import BottomBar from './Components/BottomBar'
import {   Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Home from './Components/Home';
import Transaction from './Components/Transaction';
import Mine from './Components/Mine';
import Tutorial from './Components/Tutorial'; 
import KycManage from './Components/KycManage';
import SellHistory from './Components/SellHistory';
import BuyHistory from './Components/BuyHistory';
import NotificationMessage from './Components/NotificationMessage';
import CustomerServices from './Components/CustomerServices';
import ITokenHistory from './Components/ITokenHistory';
import SellTo from './SellTo';
import Bank from './Components/Bank';
import Promotion from './Components/Promotion';
import Commision from './Components/Commision';
import Dividend from './Components/Dividend';
import InviteLink from './Components/InviteLink';
import AccountInformation from './Components/AccountInformation';
import ChangePass from './Components/ChangePass';
import LinkKycDetails from './Components/LinkKycDetails';
import DepositFund from './Components/DepositFund';
import Login from './Components/Login';
import SignUp from './Components/SignUp';
import News from './Components/News';
import ForgotPassword from './Components/ForgotPassword';
import ChangePassword from './Components/ChangePassword';
import { GrVmMaintenance } from 'react-icons/gr';

const App = () => {

  const navigate = useNavigate('');

  // useEffect(()=>{
  //   if(!localStorage.getItem('token')){
  //     navigate('/')
  //   }
  // },[])
  return (
    <>   
    

      <Routes>
        <Route path="/" element={<Login/>}/> 
        <Route path="/signUp" element={<SignUp/>}/> 
        <Route path="/Home" element={<Home/>}/> 
         <Route path="/transaction" element={<Transaction/>}/> 
        <Route path="/mine" element={<Mine/>}/>  
        <Route path="/tutorial" element={<Tutorial/>}/>  
        <Route path="/sell" element={<SellTo/>}/>  
        <Route path="/kycManage" element={<KycManage/>}/>  
        <Route path="/SellHistory" element={<SellHistory/>}/>  
        <Route path="/BuyHistory" element={<BuyHistory/>}/>  
        <Route path="/Notification" element={<NotificationMessage/>}/>  
        <Route path="/CustomerServices" element={<CustomerServices/>}/>  
        <Route path="/ITokenHistory" element={<ITokenHistory/>}/>  
        <Route path="/Bank" element={<Bank/>}/>  
        <Route path="/promotion" element={<Promotion/>}/>  
        <Route path="/commision" element={<Commision/>}/>  
        <Route path="/Dividend" element={<Dividend/>}/>  
        <Route path="/inviteLink" element={<InviteLink/>}/>  
        <Route path="/AccountInformation" element={<AccountInformation/>}/>  
        <Route path="/changePass" element={<ChangePass/>}/>  
        <Route path="/linkkycDetail" element={<LinkKycDetails/>}/>  
        <Route path="/DepositFund" element={<DepositFund/>}/>  
        <Route path="/News" element={<News/>}/>  
        <Route path="/forget" element={<ForgotPassword/>}/>  
        <Route path="/changePassword" element={<ChangePassword/>}/>  
      </Routes> 
    </>
  )
}

export default App





 