import React, { useState } from 'react';
import './Login.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { Api_Url } from '../env';
import toast, { Toaster } from 'react-hot-toast'; 
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaCopy } from 'react-icons/fa';

const SignUp = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const [data, setData] = useState('');
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500); // Reset copied state after 1.5 seconds
      })
      .catch((err) => console.error('Failed to copy:', err));
  };

  const SignUpAPi = () => {
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("number", number); // Corrected to use 'number' state variable
    formdata.append("password", password);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };

    fetch(`${Api_Url}/api/registration`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result.status === '001') { 
          toast.success(result.message);
          setData(result); // Save response data
          handleShow(); // Show modal
          // Clear input fields
          setName('');
          setEmail('');
          setPassword('');
          setNumber('');
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => console.error(error));
  }

  return (
    <>
      <div className='demo'>
        <div className='container pt-5'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 m-auto'> 
              <div className='card yac border-0' style={{backgroundColor:'transparent'}}>
                <div className='card-body'>
                  <h1 className='text-center'>SignUp</h1>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label fs-5">Enter Name</label>
                    <input type="text" className="form-control" value={name} id="name" onChange={(e) => setName(e.target.value)} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label fs-5">Enter Email</label>
                    <input type="email" className="form-control" value={email} id="email" onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="number" className="form-label fs-5">Enter Number</label>
                    <input type="number" className="form-control" value={number} id="number" onChange={(e) => setNumber(e.target.value)} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label fs-5">Enter Password</label>
                    <input type="password" className="form-control" value={password} id="password" onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <div style={{display:'flex', justifyContent:'center'}}> 
                    <button className='btn btn-primary w-50' onClick={SignUpAPi}> SignUp</button>
                  </div>
                  <div>
                    <h6>
                      <p className='text-center mt-3'>Don’t have an account? <NavLink to="/" style={{textDecoration:'none', color:'#000'}}> Sign in</NavLink></p>
                    </h6>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <Toaster />
      </div>

      <Modal show={show} onHide={handleClose} style={{backdropFilter:'blur(10px)'}}>
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <div>
            <h5 className='text-center'>UserName: <span className='ms-4'>{data?.username}</span> <FaCopy className='ms-4' onClick={() => handleCopy(data?.username)} style={{ cursor: 'pointer',color:'gray'  }} /></h5>
            <h5 className='text-center mt-4'>Password: <span className='ms-4'>{data?.password}</span> <FaCopy className='ms-4' onClick={() => handleCopy(data?.password)} style={{ cursor: 'pointer', color:'gray' }} /></h5>
            {copied && <p style={{ color: 'green' }}>Copied!</p>}
          
            <div style={{display:'flex', justifyContent:'space-around'}}> 
            <NavLink to="/">
              <button className='btn btn-primary mt-4 w-100'>Login</button>
            </NavLink>


        <NavLink to={`${Api_Url}/apk/pmwallet.apk`} > 
          <button className='btn btn-primary mt-4 '>Download App</button>
          </NavLink>
             
          </div>
          </div>
        </Modal.Body> 
      </Modal>
    </>
  );
}

export default SignUp;
