import React, { useEffect, useState } from 'react'
import flag from './Components/Img/flag.png'
import sbi from './Components/Img/sbi.png'

import { FaWallet } from "react-icons/fa";
import { IoGift } from "react-icons/io5";
import { MdViewTimeline } from "react-icons/md";
import { SiStarlingbank } from "react-icons/si";
import './App.css'
import { IoIosArrowForward } from "react-icons/io"; 
import { FaRegEdit } from "react-icons/fa";  
import { RiCustomerService2Fill } from "react-icons/ri";
import { NavLink } from 'react-router-dom';
import { Api_Url } from './env';
 
const SellTo = () => {

  const [data, setData] = useState([]);


  const GetData = async() =>{
    try {
      const formdata = new FormData();
formdata.append("token", `${localStorage.getItem('token')}`);

const requestOptions = {
  method: "POST",
  body: formdata,
  redirect: "follow"
};

fetch(`${Api_Url}/api/get-dashboard`, requestOptions)
  .then((response) => response.json())
  .then((result) =>  {
    if(result.status == "001"){
        setData(result.user)
    }
  })
  .catch((error) => console.error(error));
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    GetData()
  },[])
  return (
    <>

     <div className='container'>
        <div className='row'>
          <div className='col-lg-11 col-sm-12'>
            <h3 className='text-center pt-3'>Transaction</h3>
          </div>
        </div>
      </div>


       <div className='container'>
        <div className='row'>
          <div className='col-12 p-0 p-2'>
          <div className='card mt-3 border-0 ' style={{backgroundColor:'#efefef'}}>
        <div className='card-header border-0'>
          <div>
            <div className='row py-2' style={{backgroundColor:'#fff', borderRadius:'8px'}}>
              <div className='col-6'> 
              <div style={{display:'flex', alignItems:'center'}}> 
                 <img src={flag} alt='' style={{width:'30%'}}/>
                 <h5 className='pt-2'>I Token</h5>
              </div>
              </div>

              <div className='col-6'> 
              <div style={{display:'flex', justifyContent:'end  '}}>  
                 <h5 className='pt-2' >{data?.wallet_balance}</h5>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body'>

          <div className='row'>
            <div className='col-1'></div>
            <div className='col-5'>
               <div className='card' style={{borderRadius:'10px'}}>
               <div className='pt-2' style={{display:'flex',justifyContent:'center', }}>
                  <img src={sbi}  style={{width:'25px'}}/>
                </div>
                <div className='card-body p-0 pt-3'>
                  <h6 className='text-nowrap text-center' style={{fontSize:'14px'}}>In transaction</h6>
                  <h6 className='text-center'>0</h6>

                </div>
               </div>
            </div>


            <div className='col-5'>
               <div className='card' style={{borderRadius:'10px'}}>
               <div className='pt-2' style={{display:'flex',justifyContent:'center', }}>
               <FaWallet  style={{ backgroundColor:'#ff8e32', padding:'7px', fontSize:'32px', borderRadius:'35%', color:'#fff'}}/>

                </div>
                <div className='card-body p-0 pt-2'>
                  <h6 className='text-nowrap text-center' style={{fontSize:'14px'}}>Today Deal </h6>
                  <h6 className='text-center'>0</h6>

                </div>
               </div>
            </div>








          
          </div>

          <div className='row mt-2'>
            <div className='col-1'></div>
            <div className='col-5'>
               <div className='card' style={{borderRadius:'10px'}}>
               <div className='pt-2' style={{display:'flex',justifyContent:'center', }}>
                  <MdViewTimeline style={{fontSize:'25px', color:'#3333c1', borderRadius:'35%'}}/>
                </div>
                <div className='card-body p-0 pt-3'>
                  <h6 className='text-nowrap text-center' style={{fontSize:'14px'}}>Today times</h6>
                  <h6 className='text-center'>0</h6>

                </div>
               </div>
            </div>


            <div className='col-5'>
               <div className='card' style={{borderRadius:'10px'}}>
               <div className='pt-2' style={{display:'flex',justifyContent:'center', }}>
               <SiStarlingbank  style={{ backgroundColor:'#ff8e32', padding:'7px', fontSize:'32px', borderRadius:'35%', color:'#fff'}}/>

                </div>
                <div className='card-body p-0 pt-2'>
                  <h6 className='text-nowrap text-center' style={{fontSize:'14px'}}>Today Success </h6>
                  <h6 className='text-center'>0</h6>

                </div>
               </div>
            </div>








          
          </div>


  
        </div>
      </div>
          </div>
        </div>
       </div>   


       <div className='container mt-3'>
        <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
            <div className='col-12'>
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <h4>In Sell </h4>
                    <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" role="switch" id="switchCheckDefault" checked style={{fontSize:'25px'}}/>
   
</div>
                </div>
            </div>
        </div>




        <div className='row mt-4 pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
                     <div className='col-6'>
                        <div style={{}}>
                        <h6 className='mb-0'>In Active: 0 </h6>
                        <span className='mt-0' style={{color:'#a7a7a7', fontSize:'12px'}}>Active UPI KYC Partner</span>
                     </div>
                     </div>


                     <div className='col-6'>
                        <div style={{}}> 
                        <span className='mt-2' style={{color:'#a7a7a7', fontSize:'14px'}}>Active UPI KYC Partner</span>
                     </div>
                     </div>
                 </div>

                 <div className='row mt-4 pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
                     <div className='col-6'>
                        <div style={{}}>
                        <h6 className='mb-0'>In Active: 0 </h6>
                        <span className='mt-0' style={{color:'#a7a7a7', fontSize:'12px'}}>Manage UPI KYC Partner</span>
                     </div>
                     </div>


                     <div className='col-6'>
                        <div style={{}}> 
                        <span className='mt-2' style={{color:'#a7a7a7', fontSize:'14px'}}>manage Bange KYC Partner</span>
                     </div>
                     </div>
                 </div>
       </div>



       <div className='container-fluid mt-3'>
      <NavLink to='/transaction' style={{textDecoration:'none'}}>  <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center'}}>
            <img src={flag} style={{width:'50px'}}/>
            <h6 className='pt-2 text-dark'>Buy History</h6>
          </div>
          </div>

          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585',  fontWeight:'400'}}> <span style={{color:'#ff9b00', fontWeight:'400'}}> </span>  <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div></NavLink>
      </div>


      <div className='container-fluid mt-3'>
      <NavLink to='/SellHistory' style={{textDecoration:'none'}}>  <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center'}}>
             <FaRegEdit className='ms-3' style={{color:'#2aff2a',fontSize:'22px', fontWeight:'600'}}/>
            <h6 className='pt-2 ms-3 text-dark'>Sell History</h6>
          </div>
          </div>

          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585',  fontWeight:'400'}}> <span style={{color:'#ff9b00', fontWeight:'400'}}> </span>  <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div>  </NavLink>
      </div>


      <div className='container-fluid mt-3'>
      <NavLink to='/CustomerServices' style={{textDecoration:'none'}}>   <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center'}}>
            <RiCustomerService2Fill className="ms-3" style={{color:"#4545e3", fontSize:'22px'}}/>

            <h6 className='pt-2 ms-3 text-dark'>Official Services</h6>
          </div>
          </div>

          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585',  fontWeight:'400'}}> <span style={{color:'#ff9b00', fontWeight:'400'}}> </span>  <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div> </NavLink>
      </div>
    </>
  )
}

export default SellTo