import React from 'react'
import { IoIosArrowBack } from "react-icons/io";

const Tutorial = () => {
  return (
    <div>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12'>
                <IoIosArrowBack className='mt-3' style={{float:"left"}}/>

                    <h5 className='text-center p-2'>Tutorial</h5>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Tutorial