import React from 'react'
import { MdKeyboardArrowLeft } from "react-icons/md";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { IoFileTrayStackedSharp } from "react-icons/io5";
import { NavLink } from 'react-router-dom';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p:0}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const NotificationMessage = () => {
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>

        <div className='container py-3'>
            <div className='row'>
                <div className='col-1'> 
                <NavLink to="/mine" style={{textDecoration:'none'}}> <MdKeyboardArrowLeft  style={{fontSize:'28px', color:'#000 '}}/> </NavLink>
                </div>
                <div className='col-10'> 
                <h3 className='text-center'>Notification Message</h3>
                </div>
            </div>
        </div>



        <div className='container-fluid py-3'>
            <div className='row'>
            <div className='col-12 p-0'>
               <div>
               <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{backgroundColor:'#e7e7e7', borderRadius:'12px', display:'flex', justifyContent:'space-around'}}>
          <Tab label="Unread" {...a11yProps(0)} className="lowercase-tab2"/>
          <Tab label="All message" {...a11yProps(1)} className="lowercase-tab2"/> 
        </Tabs>
      </Box>
   
    </Box>
               </div>
            </div>



            <div className='col-12 px-0 mt-4'>
            <CustomTabPanel value={value} index={0}>

                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 p-0'>
                            <div className='py-4' style={{display:'flex', justifyContent:'center'}}>
                            <IoFileTrayStackedSharp style={{color:'#5568f9', fontSize:'70px'}} />

                            </div>
                                <h5 className='text-center'>Unread message are empty</h5>


                                      
                                <div>
                                </div>
                        </div>
                    </div>
                </div>
        </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <div className='container-fluid'>
                    <div className='row px-0'>
                        <div className='col-6 p-0'>
                            
                                <h5 className='mb-0'> Notify</h5>
                                <span style={{fontSize:'12px', color:'#c7c7c7'}} > 2024-02-26T16:59:51.234</span>
 
                                <div>
                                </div>
                        </div>

                        <div className='col-6 p-0'>
                            
                                <h6 className='mb-0'> info</h6> 
 
                                <div>
                                </div>
                        </div>
                    </div>
                </div>
      </CustomTabPanel> 
            </div>
            </div>
        </div>
    </div>
  )
}

export default NotificationMessage