import React, { useState } from 'react'
import { MdKeyboardArrowLeft } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";

import { FaCalendarCheck } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import indus from '../../src/Components/Img/IndusPay.png'
import indus1 from '../../src/Components/Img/pe.jpg'
import Airtel from '../../src/Components/Img/airtel.jpg'
import free from '../../src/Components/Img/freecharge.png'
import mobi from '../../src/Components/Img/mobi.png'
import bharat from '../../src/Components/Img/bharat.png'
import cub from '../../src/Components/Img/city.png'
import { FaChevronRight } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";


const KycManage = () => {
  const [selectedItem, setSelectedItem] = useState('');

    const [count , setCount] = useState(1);
    const [radioSelected, setRadioSelected] = useState(false);

  const handleHelloClick = () => {
    setRadioSelected(true);
  };

  const handleItemClick = (itemName) => {
    setSelectedItem(itemName); 
  };
  return (
    <>

    <div  style={{display:count==1?"block":'none'}}>
   <div className='container pt-2'>
    <div className='row'>
        <div className='col-1'>
            <div  >
           
          <NavLink to="/mine" style={{textDecoration:'none', color:'#000'}}>  <MdKeyboardArrowLeft  style={{fontSize:'18px'}}/></NavLink> 

            </div>

        </div>
        <div className='col-10'>
            <div  >
           
            <h3 className='text-center'>UPI KYC Partner  </h3>

            </div>

        </div>
    </div>
   </div>


   <div className='container mt-5'>
    <div className='row'>
        <div className='col-12'>
            <div style={{display:'flex', justifyContent:'center'}}>
        <FaCalendarCheck  style={{fontSize:'55px', color:'#5b5bff'}}/>


    </div>
    <div className='pt-2' style={{display:'flex', justifyContent:'center'}}>
        <span style={{fontSize:'12px' ,textAlign:'center'}}> kyc Partners have been linked yet</span>
    </div>
    </div> 

    <div className='col-12 mt-4'>
     <button className='btn btn-primary w-100 py-2' onClick={()=>{setCount(count+1)}} > Link Kyc Partner</button> 
    </div> 
    <div className='col-12 mt-4' style={{}}>
        <button className='btn btn-primary w-100 py-3 fixed-bottom mb-4' style={{borderRadius:'25px'}} onClick={()=>{setCount(count+1)}}>Link New UPI Kyc Partner</button>
    </div>
        </div>
   </div>

   </div>









   <div style={{display:count==2?"block":'none'}}>
   <div className='container pt-2'>
    <div className='row py-2' style={{borderBottom:'0.5px solid #e9e9e9'}}>
        <div className='col-1'>
            <div  >
           
             <MdKeyboardArrowLeft  style={{fontSize:'22px'}} onClick={()=>{setCount(count-1)}}/> 

            </div>

        </div>
        <div className='col-10'>
            <div  >
           
            <h5 className='text-center'> Link UPI KYC Partner  </h5>

            </div>

        </div>
    </div>


    <div className='row mt-3'>
        <div className='col-12'>
            <div style={{display:'flex', justifyContent:'space-between',alignItems:'center'}}>
                <span>Partner</span>
                <span style={{fontWeight:'bold'}} data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">Select the kyc Partner</span>
                  <FaChevronRight/>
            </div>
        </div>
    </div>
   </div>
   </div>


   <div style={{display:count==3?"block":'none'}}>
   <div className='container mt-2'>
            <div className='row'>
                <div className='col-2'>
               <NavLink to="/mine" style={{textDecoration:'none'}}> <IoIosArrowBack  className='text-dark' style={{fontSize:'22px'}}/></NavLink>

                </div>
                <div className='col-8'>
                <h5 className='text-center'>Link Kyc Partner</h5>

                </div>



                <div className='col-12 mt-5'>

                  <div className='row pb-2 mb-2' style={{borderBottom:'1px solid #e2e2e2'}}>
                    <div className='col-7'>
                      <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}> 
                    <h4>Partner</h4>
                <h4 className='   '>{selectedItem}</h4>
                    </div>
                    </div>

                    <div className='col-3'></div>

                    <div className='col-2 mt-1'>
                  <IoIosArrowForward  style={{float:'right'}}/> 
                    </div>
                  </div>
              
            
                <div class="input-group mb-3 pb-2"  style={{borderBottom:'1px solid #cdcdcd'}}>
                <span class="input-group-text" id="basic-addon1" style={{backgroundColor:'transparent', border:'none', color:'#000', fontWeight:'600'}}>Name</span>
                <input type="text" class="form-control" placeholder="Enter Your Name" aria-label="Username" aria-describedby="basic-addon1" style={{backgroundColor:'transparent', border:'none', boxShadow:'none'}}/>
                </div>

                <div class="input-group mb-3 pb-2"  style={{borderBottom:'1px solid #cdcdcd'}}>
                <span class="input-group-text" id="basic-addon1" style={{backgroundColor:'transparent', border:'none', color:'#000', fontWeight:'600'}}>Account</span>
                <input type="text" class="form-control" placeholder="Enter Your Account" aria-label="Username" aria-describedby="basic-addon1" style={{backgroundColor:'transparent', border:'none',boxShadow:'none'}}/>
                </div>

                <div class="input-group mb-3 pb-2"  style={{borderBottom:'1px solid #cdcdcd'}}>
                <span class="input-group-text" id="basic-addon1" style={{backgroundColor:'transparent', border:'none', color:'#000', fontWeight:'600'}}>PIN</span>
                <input type="text" class="form-control" placeholder="Enter Your Pin" aria-label="Username" aria-describedby="basic-addon1" style={{backgroundColor:'transparent', border:'none',boxShadow:'none'}}/>
                </div>

                <div className='mt-4'>
                    <button className='btn btn-primary w-100 py-2'>Link Kyc</button>
                </div>
                    
                </div>
            </div>
        </div>
   </div>





<div className='container'>
    <div className='row'>
        <div className='col-lg-12'>
 

<div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel" style={{borderRadius:'10px 10px 0px 0px', height:'75vh'}}>
  <div class="offcanvas-header">
       <small>choose a link authorization partner</small>

    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body small"> 



  {/* <NavLink to="/linkkycDetail" style={{textDecoration:'none'}}>   */}

  <div className='row mb-4' > 
 
    <div className="container" style={{display:'flex', alignItems:'center', justifyContent:'space-around'}} data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>{setCount(3)}} >
    <img src={indus} style={{width:'10%'}}/> 
      <div className="px-2 " style={{lineHeight:'5px'}} onClick={() => handleItemClick('IndusPay')}>
        <h4 id="helloText"  className='text-dark'> IndusPay</h4>
        <small style={{fontSize:'12px',color:'gray',opacity:'0'}}>IndusPay is an Indian digital payment platform</small>
      </div>
      <div className="sidebar">
        <input type="radio" id="radioButton" name="options" checked={radioSelected} readOnly  style={{fontSize:'30px'}}/>
       
      </div>
    </div> 
 
    </div>   
    <hr style={{color:'#cdcdcd'}}/> 


    <div className='row mb-4' > 

    <div className="container" style={{display:'flex', alignItems:'center', justifyContent:'space-around'}} data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>{setCount(3)}}>
    <img src={indus1} style={{width:'10%', borderRadius:'50%'}}/> 
      <div className="px-2 " style={{lineHeight:'5px'}} onClick={() => handleItemClick('Phone Pe')}>
        <h4 id="helloText" > Phone Pe</h4>
        <small style={{fontSize:'12px',color:'gray',opacity:'0'}}>IndusPay is an Indian digital payment platform</small>
      </div>
      <div className="sidebar">
        <input type="radio" id="radioButton" name="options" checked={radioSelected} readOnly  style={{fontSize:'20px'}}/>
       
      </div>
    </div> 
 
    </div> 
    <hr style={{color:'#cdcdcd'}}/> 

    <div className='row mb-4' > 

    <div className="container" data-bs-dismiss="offcanvas" aria-label="Close" style={{display:'flex', alignItems:'center', justifyContent:'space-around'}} onClick={()=>{setCount(3)}}>
    <img src={Airtel} style={{width:'10%'}}/> 
      <div className="px-2 " style={{lineHeight:'5px'}} onClick={() => handleItemClick('Airtel')}>
        <h4 id="helloText" > Airtel</h4>
        <small style={{fontSize:'12px',color:'gray',opacity:'0'}}>IndusPay is an Indian digital payment platform</small>
      </div>
      <div className="sidebar">
        <input type="radio" id="radioButton" name="options" checked={radioSelected}    style={{fontSize:'20px'}}/>
       
      </div>
    </div> 
 
    </div> 
    <hr style={{color:'#cdcdcd'}}/> 





    
    <div className='row mb-4' > 

    <div className="container" data-bs-dismiss="offcanvas" aria-label="Close" style={{display:'flex', alignItems:'center', justifyContent:'space-around'}} onClick={()=>{setCount(3)}}>
    <img src={free} style={{width:'10%'}}/> 
      <div className="px-2 " style={{lineHeight:'5px'}} onClick={() => handleItemClick('Freecharge')}>
        <h4 id="helloText" > Freecharge.</h4>
        <small style={{fontSize:'12px',color:'gray',opacity:'0'}}>IndusPay is an Indian digital payment platform</small>
      </div>
      <div className="sidebar">
        <input type="radio" id="radioButton" name="options" checked={radioSelected}    style={{fontSize:'20px'}}/>
       
      </div>
    </div> 
 
    </div> 
    <hr style={{color:'#cdcdcd'}}/> 




    
    <div className='row mb-4' > 

    <div className="container" data-bs-dismiss="offcanvas" aria-label="Close" style={{display:'flex', alignItems:'center', justifyContent:'space-around'}} onClick={()=>{setCount(3)}}>
    <img src={mobi} style={{width:'10%'}}/> 
      <div className="px-2 " style={{lineHeight:'5px'}} onClick={() => handleItemClick('Mobilwik')}>
        <h4 id="helloText" > Mobilwik.</h4>
        <small style={{fontSize:'12px',color:'gray',opacity:'0'}}>IndusPay is an Indian digital payment platform</small>
      </div>
      <div className="sidebar">
        <input type="radio" id="radioButton" name="options" checked={radioSelected}    style={{fontSize:'20px'}}/>
       
      </div>
    </div> 
 
    </div> 
    <hr style={{color:'#cdcdcd'}}/> 


    
    <div className='row mb-4' > 

    <div className="container" data-bs-dismiss="offcanvas" aria-label="Close" style={{display:'flex', alignItems:'center', justifyContent:'space-around'}} onClick={()=>{setCount(3)}}>
    <img src={bharat} style={{width:'10%'}}/> 
      <div className="px-2 " style={{lineHeight:'5px'}} onClick={() => handleItemClick('Bharat pe')}>
        <h4 id="helloText" > Bharat pe.</h4>
        <small style={{fontSize:'12px',color:'gray',opacity:'0'}}>IndusPay is an Indian digital payment platform</small>
      </div>
      <div className="sidebar">
        <input type="radio" id="radioButton" name="options" checked={radioSelected}    style={{fontSize:'20px'}}/>
       
      </div>
    </div> 
 
    </div> 
    <hr style={{color:'#cdcdcd'}}/> 


    
    <div className='row mb-4' > 

    <div className="container" data-bs-dismiss="offcanvas" aria-label="Close" style={{display:'flex', alignItems:'center', justifyContent:'space-around'}} onClick={()=>{setCount(3)}}>
    <img src={cub} style={{width:'10%'}}/> 
      <div className="px-2 " style={{lineHeight:'5px'}} onClick={() => handleItemClick('Bharat pe')}>
        <h4 id="helloText" > Cub Bank</h4>
        <small style={{fontSize:'12px',color:'gray',opacity:'0'}}>IndusPay is an Indian digital payment platform</small>
      </div>
      <div className="sidebar">
        <input type="radio" id="radioButton" name="options" checked={radioSelected}    style={{fontSize:'20px'}}/>
       
      </div>
    </div> 
 
    </div> 
    <hr style={{color:'#cdcdcd'}}/> 





  </div>
</div>
        </div>
    </div>
</div>
  
    </>
  )
}

export default KycManage