import React, { useEffect, useState } from 'react'
import flag from './Img/flag.png'
import sbi from './Img/sbi.png'
import { FaWallet } from "react-icons/fa";
import { IoGift } from "react-icons/io5";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Buy from './Buy';
import Sell from './Sell';
import { Api_Url } from '../env';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Transaction = () => {


  const [value, setValue] = React.useState(0);
const [data , setData] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [value2, setValue2] = React.useState(0);

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };


  const GetData = async() =>{
    try {
      const formdata = new FormData();
formdata.append("token", `${localStorage.getItem('token')}`);

const requestOptions = {
  method: "POST",
  body: formdata,
  redirect: "follow"
};

fetch(`${Api_Url}/api/get-dashboard`, requestOptions)
  .then((response) => response.json())
  .then((result) =>  {
    if(result.status == "001"){
        setData(result.user)
    }
  })
  .catch((error) => console.error(error));
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    GetData()
  },[])


  
  return (
    <>

      <div className='container'>
        <div className='row'>
          <div className='col-lg-11 col-sm-12'>
            <h3 className='text-center pt-3'>Transaction</h3>
          </div>
        </div>
      </div>


       <div className='container'>
        <div className='row'>
          <div className='col-12 p-0 p-2'>
          <div className='card mt-3 border-0 ' style={{backgroundColor:'#efefef'}}>
        <div className='card-header border-0'>
          <div>
            <div className='row py-2' style={{backgroundColor:'#fff', borderRadius:'8px'}}>
              <div className='col-6'> 
              <div style={{display:'flex', alignItems:'center'}}> 
                 <img src={flag} alt='' style={{width:'30%'}}/>
                 <h5 className='pt-2'>I Token</h5>
              </div>
              </div>

              <div className='col-6'> 
              <div style={{display:'flex', justifyContent:'end  '}}>  
                 <h5 className='pt-2' >{data?.wallet_balance}</h5>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body'>

          <div className='row'>
            <div className='col-4'>
               <div className='card' style={{borderRadius:'10px'}}>
               <div className='pt-2' style={{display:'flex',justifyContent:'center', }}>
                  <img src={sbi}  style={{width:'25px'}}/>
                </div>
                <div className='card-body p-0 pt-3'>
                  <h6 className='text-nowrap text-center' style={{fontSize:'14px'}}>In transaction</h6>
                  <h6 className='text-center'>0</h6>

                </div>
               </div>
            </div>


            <div className='col-4'>
               <div className='card' style={{borderRadius:'10px'}}>
               <div className='pt-2' style={{display:'flex',justifyContent:'center', }}>
               <FaWallet  style={{ backgroundColor:'#ff8e32', padding:'7px', fontSize:'32px', borderRadius:'35%', color:'#fff'}}/>

                </div>
                <div className='card-body p-0 pt-2'>
                  <h6 className='text-nowrap text-center' style={{fontSize:'14px'}}>Deal amount</h6>
                  <h6 className='text-center'>0</h6>

                </div>
               </div>
            </div>








            <div className='col-4'>
               <div className='card' style={{borderRadius:'10px'}}>
               <div className='pt-2' style={{display:'flex',justifyContent:'center', }}>
               <FaWallet  style={{ backgroundColor:'#ff8e32', padding:'7px', fontSize:'32px', borderRadius:'35%', color:'#fff'}}/>

              </div>
                <div className='card-body p-0 pt-2'>
                  <h6 className='text-nowrap text-center' style={{fontSize:'14px'}}>Total Profit</h6>
                  <h6 className='text-center'>0</h6>

                </div>
               </div>
            </div>
          </div>


 <div className='row mt-3'>
  <div className='col-6'>
    <div style={{display:'flex', alignItems:'center'}}>
  <IoGift style={{color:'#bf7b32', fontSize:'30px'}}/>

  <h6 className='pt-2 ms-3'>Today Profit</h6>
  </div>
  </div>

  <div className='col-6'> 

  <h6 className='pt-2 text-end'>0</h6>
  
  </div>
 </div>
        </div>
      </div>
          </div>
        </div>
       </div>




<div className='container-fluid mt-2'>
  <div className='row'>
    <div className='col-12 p-0'>


    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{backgroundColor:'#f1f1f1', display:'flex', justifyContent:'space-around' }}>
           
          <Tab label="Buy" {...a11yProps(0)} className='lowercase-tab2' /> 
          <Tab label="Sell" {...a11yProps(1)} className='lowercase-tab2' />
          
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className='container-fluid mt-3'>
          <div className='row'>
            <div className='col-12 p-0 '>
              <div className='pb-2' style={{display:'flex', alignItems:'center', justifyContent:'space-between',borderBottom:'0.011px solid #e9e9e9'}}>
              <h6 className='text-start'>Payment Options</h6>
              <h6 style={{float:'right'}}>Buy Tutorial</h6>
            </div> 
 
                <Buy/>
            </div> 
          </div>
        </div>
      </CustomTabPanel> 

      
      <CustomTabPanel value={value} index={1}>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>

              <h6 className='pt-3'>Sell mode</h6>
            <Sell/>
            </div>
          </div>
        </div>
      </CustomTabPanel> 
    </Box>

    </div>
  </div>
</div>


    </>
  )
}

export default Transaction