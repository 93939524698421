import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BottomBar from './BottomBar';
import { Api_Url } from '../env';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  return (
    <div>
      <div className='container p-0 mt-3'>
        <div className='row'>
          <div className='col-12 p-0'>
            
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='p-0'>
                  <Tab label="Entrust" {...a11yProps(0)}  className='lowercase-tab2'/> 
                  <Tab label="Manual" {...a11yProps(1)} className='lowercase-tab2'/> 
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                 
                 <div className='mt-3' style={{  color:'#ff9b00'}}>
                 <h6>Sell Your itoken automatically</h6>
                 <h6>keep your assets safe</h6>
                 <h6> Entrust the sell of itoken , no tarnsation fee</h6>
                 </div> 

                 <div className='row mt-4 '>
                     <div className='col-6'>
                        <div style={{}}>
                        <h6 className='mb-0'>In Active: 0 </h6>
                        <span className='mt-0' style={{color:'#a7a7a7', fontSize:'12px'}}>Active UPI KYC Partner</span>
                     </div>
                     </div>


                     <div className='col-6'>
                        <div style={{}}> 
                        <span className='mt-2' style={{color:'#a7a7a7', fontSize:'14px'}}>Active UPI KYC Partner</span>
                     </div>
                     </div>
                 </div>

                 <div className='row mt-4 '>
                     <div className='col-6'>
                        <div style={{}}>
                        <h6 className='mb-0'>In Active: 0 </h6>
                        <span className='mt-0' style={{color:'#a7a7a7', fontSize:'12px'}}>Manage UPI KYC Partner</span>
                     </div>
                     </div>


                     <div className='col-6'>
                        <div style={{}}> 
                        <span className='mt-2' style={{color:'#a7a7a7', fontSize:'14px'}}>manage Bange KYC Partner</span>
                     </div>
                     </div>
                 </div>

                 <div className='mt-4' style={{display:'flex', justifyContent:'center'}}>
                    <button className='btn btn-primary' style={{  width:'95%', padding:'10px', borderRadius:'8px'}}>In Sell</button>
                </div> 
              </CustomTabPanel> 





              <CustomTabPanel value={value} index={1}>
          
              <div className='mt-3' style={{  color:'#ff9b00'}}>
                 <h6>Sell Your itoken automatically</h6>
                 <h6>keep your assets safe</h6>
                 <h6> Entrust the sell of itoken , no tarnsation fee</h6>
                 </div>


 

              </CustomTabPanel>  
            </Box>
          </div>
        </div>
      </div>

      <BottomBar/>
    </div>
  );
}
