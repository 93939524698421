import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate,   } from 'react-router-dom';
import { Api_Url } from '../env';
import toast, { Toaster } from 'react-hot-toast';
import OTPInput, { ResendOTP } from "otp-input-react";


const ChangePassword = () => {
    
    
const Navigate = useNavigate();

  const [count , setCount] = useState('');
  const [OTP, setOTP] = useState("");
  const [Password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");

  const [name , setName] = useState('');

  const [isMatch, setIsMatch] = useState(true); // State to track if passwords match

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    // Check if passwords match when the new password is changed
    setIsMatch(e.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setconfirmPassword(e.target.value);
    // Check if passwords match when the confirm password is changed
    setIsMatch(e.target.value === Password);
  };
    
  const ChangePassword2 = ()=>{

    const formdata = new FormData();
formdata.append("password", Password);
formdata.append("password_confirmation", confirmPassword);
formdata.append("token", `${localStorage.getItem('token')}`);



const requestOptions = {
  method: "POST",
  body: formdata,
  redirect: "follow"
};

fetch(`${Api_Url}/api/validate-reset-password`, requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if(result.status == "001"){
      toast.success(result.message)
      Navigate('/') 


      setTimeout(() => {

        setPassword('')
        setconfirmPassword('')

      }, 4500);
    }else{
      toast.error(result.message)
    }
  })
  .catch((error) => console.error(error));
  }
 
  return (
    <div>

<div className='container pt-5'>
        <div className='row '>
          <div className='col-lg-6 col-md-6 col-sm-12 mt-4 m-auto'> 

          <div className='card yac border-0' style={{backgroundColor:'transparent'}}>
            <div className='card-body'> 
             <h4 className='text-center my-4'>Change Password</h4>

                 
             <div className="mb-3">
        <label htmlFor="newPassword" className="form-label">Enter New Password</label>
        <input type="password" id="newPassword" className="form-control py-2" value={Password} onChange={handlePasswordChange}/>
      </div>

      <div className={`mb-3 ${!isMatch ? 'has-error' : ''}`}>
        <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
        <input type="password" id="confirmPassword" className="form-control py-2" value={confirmPassword} onChange={handleConfirmPasswordChange}/>
        {!isMatch && <small className="text-danger">Passwords do not match</small>}
      </div>

          <div>
            <button className='btn btn-primary py-2' onClick={ChangePassword2}> Change Password</button>
          </div>

            </div> 
           </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword