import React, { useState } from 'react'
import { IoIosArrowBack } from "react-icons/io";
import { NavLink } from 'react-router-dom';
import { FaChevronRight } from "react-icons/fa6";
import indus from '../../src/Components/Img/IndusPay.png'

const LinkKycDetails = () => {
    const [radioSelected, setRadioSelected] = useState(false);

    const handleHelloClick = () => {
        setRadioSelected(true);
      };
  return (
    <div>
        <div className='container mt-2'>
            <div className='row'>
                <div className='col-2'>
               <NavLink to="/mine" style={{textDecoration:'none'}}> <IoIosArrowBack  className='text-dark' style={{fontSize:'22px'}}/></NavLink>

                </div>
                <div className='col-8'>
                <h5 className='text-center'>Link Kyc Partner</h5>

                </div>
            </div>
        </div>

        <div className='container mt-3'>
            <div className='row'> 
           
                <div className='col-12'>
                <div className='my-3 mb-3' style={{display:'flex', justifyContent:'space-between',alignItems:'center'}}  data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                <span>Partner</span>
                <span style={{fontWeight:'bold'}}>Select the kyc Partner</span>
                  <FaChevronRight/>
                 </div>
                     

                <div class="input-group mb-2 pb-2"  style={{borderBottom:'1px solid #cdcdcd'}}>
                <span class="input-group-text" id="basic-addon1" style={{backgroundColor:'transparent', border:'none', color:'#000', fontWeight:'600'}}>Name</span>
                <input type="text" class="form-control" placeholder="Enter Your Name" aria-label="Username" aria-describedby="basic-addon1" style={{backgroundColor:'transparent', border:'none', boxShadow:'none'}}/>
                </div>

                <div class="input-group mb-2 pb-2"  style={{borderBottom:'1px solid #cdcdcd'}}>
                <span class="input-group-text" id="basic-addon1" style={{backgroundColor:'transparent', border:'none', color:'#000', fontWeight:'600'}}>Account</span>
                <input type="text" class="form-control" placeholder="Enter Your Account" aria-label="Username" aria-describedby="basic-addon1" style={{backgroundColor:'transparent', border:'none',boxShadow:'none'}}/>
                </div>

                <div class="input-group mb-2 pb-2"  style={{borderBottom:'1px solid #cdcdcd'}}>
                <span class="input-group-text" id="basic-addon1" style={{backgroundColor:'transparent', border:'none', color:'#000', fontWeight:'600'}}>PIN</span>
                <input type="text" class="form-control" placeholder="Enter Your Pin" aria-label="Username" aria-describedby="basic-addon1" style={{backgroundColor:'transparent', border:'none',boxShadow:'none'}}/>
                </div>

                <div className='mt-4'>
                    <button className='btn btn-primary w-100 py-2'>Link Kyc</button>
                </div>
                    
                </div>
            </div>
        </div>

        {/* ofcanvash start */}
        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel" style={{borderRadius:'10px 10px 0px 0px', height:'70vh'}}>
  <div class="offcanvas-header">
       <small>choose a link authorization partner</small>

    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body small"> 



   


    <div className='row mb-4' > 

    <div className="container" style={{display:'flex', alignItems:'center', justifyContent:'space-around'}}>
    <img src={indus} style={{width:'10%'}}/> 
      <div className="px-2 " style={{lineHeight:'5px'}} onClick={handleHelloClick}>
        <h4 id="helloText" > IndusPay</h4>
        <small style={{fontSize:'12px',color:'gray'}}>IndusPay is an Indian digital payment platform</small>
      </div>
      <div className="sidebar">
        <input type="radio" id="radioButton" name="options" checked={radioSelected} readOnly  style={{fontSize:'20px'}}/>
       
      </div>
    </div> 
 
    </div> 
    <hr style={{color:'#cdcdcd'}}/> 

    <div className='row mb-4' > 

    <div className="contax`iner" style={{display:'flex', alignItems:'center', justifyContent:'space-around'}}>
    <img src={indus} style={{width:'10%'}}/> 
      <div className="px-2 " style={{lineHeight:'5px'}} onClick={handleHelloClick}>
        <h4 id="helloText" > IndusPay</h4>
        <small style={{fontSize:'12px',color:'gray'}}>IndusPay is an Indian digital payment platform</small>
      </div>
      <div className="sidebar">
        <input type="radio" id="radioButton" name="options" checked={radioSelected}    style={{fontSize:'20px'}}/>
       
      </div>
    </div> 
 
    </div> 
    <hr style={{color:'#cdcdcd'}}/> 
  </div>
</div>
        
        {/* ofcanvash end */}
    </div>
  )
}

export default LinkKycDetails