import React from 'react'
import { NavLink } from 'react-router-dom';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { IoFileTrayStackedSharp } from "react-icons/io5";

const ITokenHistory = () => {
  return (
    <div> 
         <div className='container py-3'>
    <div className='row'>
        <div className='col-1'> 
        <NavLink to="/mine" style={{textDecoration:'none'}}> <MdKeyboardArrowLeft  style={{fontSize:'28px', color:'#000 '}}/> </NavLink>
        </div>
        <div className='col-10'> 
        <h3 className='text-center'>IToken History</h3>
        </div>
    </div>
</div>

<div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 p-0'>
                            <div className='py-4' style={{display:'flex', justifyContent:'center'}}>
                            <IoFileTrayStackedSharp style={{color:'#5568f9', fontSize:'70px'}} />

                            </div>
                                <h5 className='text-center'>Itoken has not been buy in this way</h5>


                                    <NavLink to="/transaction"><button className='btn  mt-4' style={{backgroundColor:'#0202cb', color:'#fff', padding:'10px', width:'100%', borderRadius:'20px'}}>To Buy</button> </NavLink>
                                <div>
                                </div>  
                        </div>
                    </div>
                </div>
</div>
  )
}

export default ITokenHistory