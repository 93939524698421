import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { MdKeyboardArrowLeft } from "react-icons/md";
import { IoFileTrayStackedSharp } from "react-icons/io5";
const Commision = () => {
   
    const [selectedValue, setSelectedValue] = useState('Commission');

    const handleChange = (e) => {
      setSelectedValue(e.target.value);
    };

    const data = [
        { id: 1, name: 'Commission' },
        { id: 2, name: 'Buy' },
        { id: 3, name: 'Reward' },
        { id: 4, name: 'Comminssion' },
        { id: 5, name: 'Dividend' },
        { id: 6, name: 'Deducted' },
        { id: 7, name: 'Return' },
        { id: 8, name: 'Fee' },
        { id: 9, name: 'Admin Add' },
        { id: 10, name: 'Admin deducted' },
        { id: 11, name: 'Transfer out' },
        { id: 12, name: 'Transfer in' },
      ];
  return (
    <div>

<div className='container pt-3'>
    <div className='row'>
        <div className='col-1'>
            <div  >
           
          <NavLink to="/mine" style={{textDecoration:'none', color:'#000'}}>  <MdKeyboardArrowLeft  style={{fontSize:'18px'}}/></NavLink> 

            </div>

        </div>
        <div className='col-10'>
            <div  >
           
            <h3 className='text-center'>IToken History  </h3>

            </div>

        </div>
    </div>
   </div>



        <div className='container mt-4'>
            <div className='row'>
                <div className='col-lg-12'>
                <select
        className="form-select"
        aria-label="Default select example"
        style={{
          border: 'none',
          borderBottom: '1px solid #d9d9d9',
          fontSize: '18px',
          boxShadow: 'none',
        }}
        value={selectedValue}
        onChange={handleChange}
      >
        {data.map((item) => (
          <option key={item.id} value={item.name}>
            {item.name}
          </option>
        ))}
      </select>

                </div>

                 <div>
       </div>
            </div>
        </div>




        <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 p-0'>
                            <div className='py-4' style={{display:'flex', justifyContent:'center'}}>
                            <IoFileTrayStackedSharp style={{color:'#5568f9', fontSize:'70px'}} />

                            </div>
                                <h5 className='text-center'>Itoken has not been buy in this way</h5>


                                    <NavLink to="/transaction"><button className='btn  mt-4' style={{backgroundColor:'#0202cb', color:'#fff', padding:'10px', width:'100%', borderRadius:'20px'}}>To Buy</button> </NavLink>
                                <div>
                                </div>  
                        </div>
                    </div>
                </div>
    </div>
  )
}

export default Commision