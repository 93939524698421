import React, { useEffect, useState } from 'react'
  import Avtaar from './Img/abc.png'
import { IoIosArrowForward } from "react-icons/io";
import flag from './Img/flag.png'
import { BsFillSuitcaseLgFill } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import Bhim from './Img/bhim.png'
import { FaWallet } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";
import { RiLuggageDepositLine } from "react-icons/ri";
import { FaUserFriends } from "react-icons/fa";
import BottomBar from './BottomBar';
import { NavLink, useNavigate } from 'react-router-dom';
import { Api_Url } from '../env';

const Mine = () => {

 const navigate = useNavigate();
const [data , setData] = useState()
  const Logout =() =>{
    localStorage.removeItem('token')
    navigate('/')
  }


  const GetData = async() =>{
    try {
      const formdata = new FormData();
formdata.append("token", `${localStorage.getItem('token')}`);

const requestOptions = {
  method: "POST",
  body: formdata,
  redirect: "follow"
};

fetch(`${Api_Url}/api/get-dashboard`, requestOptions)
  .then((response) => response.json())
  .then((result) =>  {
    if(result.status == "001"){
        setData(result.user)
    }
  })
  .catch((error) => console.error(error));
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    GetData()
  },[])
  return (
    <div style={{height:'98vh', overflowY:'scroll'}}>

    <div className='container'>
      <div className='row'>
        <div className='col-lg-12'>
        <h2  className='text-center p-3'>Mine</h2>
        </div>
      </div>
    </div>
      <div className='container-fluid'>
        <div className='row pb-3' style={{borderBottom:'0.2px solid #e9e9e9'}}>
            
          

          <div className='col-6 ps-0'>
            <div className='mt-1' style={{display:'flex', alignItems:'center'}}>
              <img src={Avtaar} style={{width:'90px'}}/>
            <p className='text-nowrap pt-3' style={{fontSize:'12px', color:'#858585'}}>Reward ratio : 1.20 </p>
            </div>

          </div>

          <div className='col-6'>
            <div className='mt-1' style={{display:'flex', alignItems:'center',justifyContent:'end'}}> 
           <NavLink to="/AccountInformation" style={{textDecoration:'none'}}>  <p className='text-nowrap pt-3' style={{fontSize:'12px', color:'#858585'}}>ID: {data?.username} <IoIosArrowForward  style={{fontSize:'16px'}}/>
 </p> </NavLink>
            </div>

          </div>
        </div>
      </div>



      <div className='container-fluid mt-3'>
      <NavLink to="/transaction" style={{textDecoration:'none'}}>  <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center'}}>
            <img src={flag} style={{width:'50px'}}/>
            <h6 className='pt-2 text-dark'>I Token</h6>
          </div>
          </div>

          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585', fontWeight:'400' }}> <span style={{color:'#ff9b00'}}>{data?.wallet_balance} IToken </span>Buy <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div></NavLink>
      </div>


      
      <div className='container-fluid mt-3'>
     <NavLink to='/sell' style={{textDecoration:'none'}}> <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-6'>
            <div  style={{display:'flex', alignItems:'center'}}>
            <BsFillSuitcaseLgFill className='ms-3' style={{color:'#4646d5', fontSize:'20px', fontWeight:'600'}} />
           

            <h6 className='pt-2 ms-3 text-dark'>Sell</h6>
          </div>
          </div>

          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585',  fontWeight:'400'}}> <span style={{color:'#ff9b00', fontWeight:'400'}}> </span>Open Sell <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div></NavLink>
      </div>



       
      <div className='container-fluid mt-3'>
        <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-6'>
          <NavLink to='/SellHistory' style={{textDecoration:'none'}}>  <div style={{display:'flex', alignItems:'center'}}>
           <FaRegEdit className='ms-3' style={{color:'#2aff2a',fontSize:'22px', fontWeight:'600'}}/>
            <h6 className='pt-2 ms-3' style={{color:'#000'}}>Sell History</h6> 
          </div></NavLink>
          </div>

          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585',  fontWeight:'400'}}> <span style={{color:'#ff9b00', fontWeight:'400'}}> </span>  <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div>
      </div>

       
      <div className='container-fluid mt-3'>
        <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-6'>
          <NavLink to="/BuyHistory" style={{textDecoration:'none'}}>   <div style={{display:'flex', alignItems:'center'}}>
            <FaCalendarAlt  className='ms-3' style={{color:'#37a1d5', fontSize:'22px'}}/>

            <h6 className='pt-2 ms-3' style={{color:'#000'}}>Buy History</h6>
          </div>  </NavLink>
          </div>

          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585',  fontWeight:'400'}}> <span style={{color:'#ff9b00', fontWeight:'400'}}> </span>  <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div>
      </div>

       
      <div className='container-fluid mt-3'>
        <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-6'>
          <NavLink to="/ITokenHistory" style={{textDecoration:'none'}}>  <div style={{display:'flex', alignItems:'center'}}>
            <img src={flag} style={{width:'50px'}}/>
            <h6 className='pt-2 text-dark'>IToken History</h6>
          </div></NavLink>
          </div>

          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585',  fontWeight:'400'}}> <span style={{color:'#ff9b00', fontWeight:'400'}}> </span>  <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div>
      </div>

       
      <div className='container-fluid mt-3'>
        <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-6'>
           <NavLink to="/kycManage" style={{textDecoration:'none'}}> <div style={{display:'flex', alignItems:'center'}}>
            <img src={Bhim} style={{width:'30px'}} className='ms-2'/>
            <h6 className='pt-2 ms-3 text-dark  '>UPI KYC Partner</h6>
          </div></NavLink>
          </div>

          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585',  fontWeight:'400'}}> <span style={{color:'#ff9b00', fontWeight:'400'}}> </span>  <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div>
      </div>
       
      <div className='container-fluid mt-3'>
        <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-6'>
          <NavLink to="/Bank" style={{textDecoration:'none'}}>  <div style={{display:'flex', alignItems:'center'}}>
            <FaWallet className="ms-3" style={{color:'#009fff',fontWeight:'600', fontSize:'18px'}}/>
            <h6 className='pt-2 ms-3 text-dark'>Bank KYC Partner</h6>
          </div> </NavLink>
          </div>

          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585',  fontWeight:'400'}}> <span style={{color:'#ff9b00', fontWeight:'400'}}> </span>  <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div>
      </div>
      <div className='container-fluid mt-3'>
        <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-6'>
          <NavLink to="/DepositFund" style={{textDecoration:'none'}}>  <div style={{display:'flex', alignItems:'center'}}>
            <RiLuggageDepositLine className="ms-3" style={{color:'#009fff',fontWeight:'600', fontSize:'18px'}}/>
            <h6 className='pt-2 ms-3 text-dark'>Deposit Fund</h6>
          </div> </NavLink>
          </div>

          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585',  fontWeight:'400'}}> <span style={{color:'#ff9b00', fontWeight:'400'}}> </span>  <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div>
      </div>
       
      <div className='container-fluid mt-3'>
        <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-8'>
           <NavLink to="/promotion" style={{textDecoration:'none'}}>  <div style={{display:'flex', alignItems:'center'}}>
            <FaUserFriends className="ms-3" style={{color:'#5eff38',fontWeight:'600', fontSize:'22px'}} />

            <h6 className='pt-2 ms-3 text-dark'>Promote more revenue</h6>
          </div>  </NavLink>
          </div>

          <div className='col-4'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585',  fontWeight:'400'}}> <span style={{color:'#ff9b00', fontWeight:'400'}}> </span>  <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div>
      </div>



      <div className='container-fluid mt-3'>
        <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-6'>
           <NavLink to="/tutorial" style={{textDecoration:'none'}}> <div style={{display:'flex', alignItems:'center'}}>
            <FaPlay className="ms-3"  style={{color:'#000',fontWeight:'600', fontSize:'22px'}}/>

            <h6 className='pt-2 ms-3 text-dark'>Tutorial</h6>
          </div></NavLink>
          </div>

          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585',  fontWeight:'400'}}> <span style={{color:'#ff9b00', fontWeight:'400'}}> </span>  <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div>
      </div>



      <div className='container-fluid mt-3'>
        <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-6'>
           <NavLink to="/Notification" style={{textDecoration:'none'}}> <div style={{display:'flex', alignItems:'center'}}>
            <FaCalendarAlt  className='ms-3' style={{color:'#37a1d5', fontSize:'22px'}}/>
             <h6 className='pt-2 ms-3 text-dark'>Message</h6>
          </div></NavLink>
          </div>

          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585',  fontWeight:'400'}}> <span style={{color:'#ff9b00', fontWeight:'400'}}> </span>  <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div>
      </div>


      <div className='container-fluid mt-3'>
        <div className='row pb-2' style={{borderBottom:'0.2px solid #e9e9e9'}}>
          <div className='col-6'>
          <NavLink to="/CustomerServices" style={{textDecoration:'none'}}>  <div style={{display:'flex', alignItems:'center'}}>
            <RiCustomerService2Fill className="ms-3" style={{color:"#4545e3", fontSize:'22px'}}/>

            <h6 className='pt-2 ms-3 text-dark'>Official Services</h6>
          </div> </NavLink>
          </div>

          <div className='col-6'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
            <h6 className='pt-2' style={{ color:'#858585',  fontWeight:'400'}}> <span style={{color:'#ff9b00', fontWeight:'400'}}> </span>  <IoIosArrowForward  style={{fontSize:'16px'}}/></h6>
          </div>
          </div>
        </div>
      </div>

      <hr/>

      <div className='container mb-5 '>
        <div className='row'>
          <div className='col-12 mb-5'>
            <h3 className='text-center' style={{cursor:'pointer'}} onClick={()=>{Logout()}}>Sign Out</h3>
          </div>
        </div>
      </div>
      <BottomBar/>
    </div>
  )
}

export default Mine