import React, { useEffect, useState } from 'react'
import './Login.css'
import { NavLink, useNavigate } from 'react-router-dom'; 
import { Api_Url } from '../env';
import toast, { Toaster } from 'react-hot-toast';


const Login = () => {

  

const [userName , setUserName] = useState('');
const [Pass , setPass] = useState('');


const Navigate = useNavigate()

  const Login2 =  ()=>{
   
      const formdata = new FormData();
    formdata.append("username", userName);
    formdata.append("password", Pass);
    
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };
    
    fetch(`${Api_Url}/api/login`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result.status === "001"){
          localStorage.setItem("token", result.token); 
          toast.success(result.message) 
          Navigate('/Home')
        }
        else{
          toast.error(result.message)
        }
      })
      .catch((error) => console.error(error));
      
    }
  



  return (
    <div className='demo'>
      <div className='container pt-5'>
        <div className='row '>
          <div className='col-lg-6 col-md-6 col-sm-12 mt-4 m-auto'> 

          <div className='card yac border-0' style={{backgroundColor:'transparent'}}>
            <div className='card-body'> 
           <h1 className='text-center my-4'>Login</h1>
          <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label  fs-5">  User Name</label>
    <input type="text" class="form-control" id="exampleInputEmail1" value={userName} aria-describedby="emailHelp"  onChange={(e)=>{setUserName(e.target.value)}}/>
    
  </div>

          <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label  fs-5">  Password</label>
    <input type="password" class="form-control" id="exampleInputEmail1" value={Pass} aria-describedby="emailHelp" onChange={(e)=>{setPass(e.target.value)}}/>

    <NavLink to="/forget"> <p className='pt-2' style={{color:'blue', float:"right",fontWeight:'500'}}>Forgot Password</p>
    </NavLink>
  </div>

<div className='mt-5' style={{display:'flex', justifyContent:'center'}}> 
  <button className='btn btn-primary w-50' onClick={()=>{Login2()}}> Login</button>
</div>

<div>
  <h6> <p className='text-center mt-3'>Don’t have an account? <NavLink to="/signUp" style={{textDecoration:'none', color:'blue'}}> Sign up.</NavLink></p></h6>
  
</div> 

          </div>


          </div>
          </div>
        </div>
      </div>

      <Toaster/>
    </div>
  )
}

export default Login